import React, { useState } from "react";
import { useRouter } from "next/router";
import { useToast, Heading, Box, IconButton } from "@chakra-ui/react";
import Head from "next/head";
import { captureException } from "@sentry/nextjs";
import { QuestionIcon } from "@chakra-ui/icons";
import { LoginButtons } from "@/components/Login/LoginButtons";
import { Logo } from "@/components/Logo";
import { PageLoading } from "@/components/PageLoading";
import { Toast } from "@/components/Toast";
import {
  useAuthUser,
  getOrCreateUser,
  logOut,
} from "@/services/authentication";
import { getUserSavedMusics } from "@/services/user";
import { useStoreState, useStoreActions } from "@/store";

const Login = () => {
  const { replace, push } = useRouter();
  const currentUser = useStoreState((state) => state.currentUser.value);
  const setUser = useStoreActions((action) => action.currentUser.setUser);
  const setSavedMusics = useStoreActions(
    (action) => action.savedMusics.setSavedMusics
  );
  const [pageLoading, setPageLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);

  const toast = useToast();

  useAuthUser(
    async (user) => {
      const userFromFirestore = await getOrCreateUser(user, currentUser);
      if (!userFromFirestore) {
        setPageLoading(false);
        return toast({
          render: () => <Toast>Couldn't get or create user.</Toast>,
        });
      }
      setUser({ ...userFromFirestore });
      try {
        const savedMusics = await getUserSavedMusics(userFromFirestore.id);
        setSavedMusics([...savedMusics]);
        return replace("/me");
      } catch (e) {
        console.error(e);
        captureException(e);
        await logOut();
        setUser(undefined);
        setPageLoading(false);
        toast({
          position: "top",
          render: () => <Toast>An error occured. 🤕 Please try again.</Toast>,
        });
      }
    },
    () => setPageLoading(false)
  );

  if (pageLoading) {
    return <PageLoading />;
  }
  return (
    <div className="container vertical full-height-screen">
      <Head>
        <title>Jamhouse - Login</title>
      </Head>
      <div
        className="flex items-center justify-center vertical"
        style={{ width: "90%", maxWidth: "300px" }}
      >
        <IconButton
          right="20px"
          top="calc(env(safe-area-inset-top) + 20px)"
          pos="absolute"
          icon={<QuestionIcon fontSize="25px" />}
          size="lg"
          variant="ghost"
          aria-label="help"
          onClick={() => push("/contact")}
        />
        <Logo />
        {currentUser?.firstName && !loginLoading && (
          <Heading mt={4} size="md" textAlign="center">
            {currentUser.firstName.split(" ")[0]}, you're a step away from
            hosting your rooms 👇
          </Heading>
        )}
        <Box my={3} />
        <LoginButtons onLoad={setLoginLoading} />
      </div>
    </div>
  );
};

export default Login;
